import axios from 'axios';
import { TEMPLATE_CONFIG_ICEDEMO, TEMPLATE_CONFIG_FIRSTCOMMUNITY } from './templateConfig';
//here is where we are defining our custom axios instance.
const baseURL = `${process.env.REACT_APP_BORROWER_PORTAL_EXPERIENCE_API}${process.env.REACT_APP_API_PREFIX}`;
console.log(`baseURL: ${baseURL}`);

const apiClient = (token) => {
  let axiosInstance = axios.create({
    //TODO: Remove default dev url. It should error if env variables are not correctly defined
    baseURL: `${process.env.REACT_APP_BORROWER_PORTAL_EXPERIENCE_API}${process.env.REACT_APP_API_PREFIX}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      //'mocked-origin': 'https://firstcommunity.qa.pos.firstclose.com/borrowerportal',
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error?.response?.status >= 400) {
        //auxHandleChangeErrorScreen(true);
        console.log('Axios apiClient error.response.status', error.response.status);
        console.log(`Error details: ${JSON.stringify(error.response.data)}`);
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

// A class that holds endpoints - no worries, we will get the errors on each page we call these functions :)

export default class FcRoutes {
  constructor(token) {
    this.token = token;
  }

  // default
  async getDefault() {
    return apiClient(this.token).get('/');
  }

  /**
   * Loan
   * @deprecated This function is deprecated and will be removed in future versions. Please use getLoans() instead.
   */
  async getLoanInfo(id) {
    return apiClient(this.token).get(`/loans/${id}/summary`);
  }

  //loans
  async getLoans() {
    return await apiClient(this.token).get(`/loans`);
  }

  //file
  async getFileById(id) {
    return apiClient(this.token).get(`/file/${id}`);
  }

  // document
  async getDocumentById(id) {
    return apiClient(this.token).get(`/document/${id}`);
  }

  async addDocument(data) {
    return apiClient(this.token).post('/document', JSON.stringify(data));
  }

  // tasks
  async addTask(data) {
    return apiClient(this.token).post('/task', JSON.stringify(data));
  }

  async getTasks(loanId, borrowerType) {
    return apiClient(this.token).get(`/task/all?loanId=${loanId}&borrowerType=${borrowerType}`);
  }

  async getTaskById(id) {
    return apiClient(this.token).get(`/task/${id}`);
  }

  async getTaskByLoanIdAndTaskId(loanId, taskId) {
    return apiClient(this.token).get(`/task?loanId=${loanId}&taskId=${taskId}`);
  }

  async getTaskDocuments(loanId, taskId, documentName) {
    return apiClient(this.token).get(`/task/documents?loanId=${loanId}&taskId=${taskId}&documentName=${documentName}`);
  }

  async patchTask(data) {
    return apiClient(this.token).patch(`/task/complete`, data);
  }

  // closing
  async getClosingById() {
    return apiClient(this.token).get(`/closing/${id}`);
  }

  async getClosingDocsById(loanNumber) {
    return apiClient(this.token).get(`/closing/closing-docs?loanNumber=${loanNumber}`);
  }

  async addClosing(data) {
    return apiClient(this.token).post('/closing', JSON.stringify(data));
  }

  async patchClosing(id, params, headers) {
    return apiClient(this.token).patch(`/closing/${id}`, params, headers);
  }

  // Identity
  async addIdentity(data) {
    return apiClient(this.token).post('/identity', data);
  }

  //signing-room
  async getSigningRoomById(id) {
    return apiClient(this.token).get(`/signing-room/${id}`);
  }

  async getSigningRoomByIdLatest(email, signingRoomId) {
    return apiClient(this.token).get(`signing-room/url/latest?email=${encodeURIComponent(email)}&signingRoomId=${signingRoomId}`);
  }

  async addSigningRoom(data) {
    return apiClient(this.token).post('/signing-room', data);
    //return apiClient(this.token).post("/signing-room", JSON.stringify(data));
  }

  async patchSigningRoom(id, params, headers) {
    return apiClient(this.token).patch(`/signing-room/${id}`, params, headers);
  }

  /**
   * User
   * @deprecated This function is deprecated and will be removed in future versions. Please use getLoans() instead.
   */
  async getUser() {
    return apiClient(this.token).get(`/user`);
  }

  async patchUser(loanId) {
    return apiClient(this.token).patch(`/user/hasVisitedSplashPage?loanId=${loanId}`);
  }

  //messages
  async getMessages(loanId) {
    return apiClient(this.token).get(`/messaging?loanId=${loanId}`);
  }

  async postMessage(data) {
    return apiClient(this.token).post('/messaging', data);
  }

  async getToken(loanId) {
    return apiClient(this.token).get(`/disclosures/widget?loanId=${loanId}`);
  }

  async getTemplateConfig() {
    /*
    ICE DEMO "https://demonstration-equityiqice-portal-test.tavros.firstclose.com/borrowerportal"
    ICE "https://application.equityiqice.app.tavros.firstclose.com/borrowerportal"
    FIRSTCOMMUNITY "https://firstcommunity.dev.tavros.firstclose.com/borrowerportal"
    */
    let response = await axios.get(`${process.env.REACT_APP_CONFIG_API}${process.env.REACT_APP_API_PREFIX}/config`, {
      headers: {
        'api-key': process.env.REACT_APP_API_KEY || 'none',
        //'mocked-origin': 'https://firstcommunity.qa.pos.firstclose.com/borrowerportal',
      },
    });
    let TemplateConfig = {
      ...response.data?.ui?.borrowerPortalConfig,
      auth0: response.data?.features?.userCreation?.config?.auth0,
      features: {...response.data?.features, ...response.data?.ui?.borrowerPortalConfig?.features},
    };

    return {
      data: {
        ...TemplateConfig,
        globalTheme: { ...TemplateConfig.globalTheme, favicon: response.data?.ui?.globalTheme?.favicon ?? response.data?.ui?.globalTheme?.logo },
      },
    };
  }
}
